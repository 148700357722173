@use '../../styleguide/colors.scss';

.root {
  background: colors.$primary-color-light-shade;
}

.content {
  height: 42px;
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  color: white;
  align-items: center;
  justify-content: flex-start;
  grid-auto-columns: max-content;
  overflow-y: hidden;
  overflow-x: auto;
}

.term {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
  font-stretch: condensed;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  text-transform: capitalize;
}
