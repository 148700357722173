@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.activeFiltersContainer {
  display: flex;
  flex: 0 0 auto;
  margin: 15px 0;
}

.activeFilters {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  list-style-type: none;
}

.activeFilterPill {
  align-items: center;
  background-color: colors.$primary-color;
  border-radius: 4px;
  color: colors.$white;
  cursor: pointer;
  display: flex;
  font-size: 0.813rem;
  height: 25px;
  justify-content: space-between;
  line-height: 1.563rem;
  margin: 0 5px 5px 0;
  min-width: 0;
  padding: 0 10px;
  text-decoration: none;

  &Text {
    font-size: 0.87rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Cross {
    align-items: center;
    color: colors.$white;
    display: flex;
    font-size: 1.7rem;
    height: 100%;
    margin-left: 10px;
    text-decoration: none;
  }
}

.clearAllFilters {
  align-items: center;
  border-left: solid 1px colors.$grey;
  display: flex;
  flex: 0 0 auto;
  margin-left: 10px;
  padding-left: 10px;
}

.clearAllFiltersCrossText {
  display: none;
  font-size: 0.87rem;
  @media screen and (min-width: vars.$medium) {
    display: inline-block;
  }
}

.clearAllFiltersCross {
  align-items: center;
  display: flex;
  color: colors.$secondary-text;
  text-decoration: none;
}

.clearAllFiltersCrossIcon {
  align-items: center;
  background: colors.$secondary-text;
  border-radius: 50%;
  color: colors.$white;
  display: flex;
  flex: 0 0 auto;
  font-size: 1.1rem;
  height: 18px;
  justify-content: center;
  margin-right: 5px;
  width: 18px;
}

.rootInvertedColors {
  .clearAllFiltersCrossText {
    color: white;
  }
  .activeFilterPill {
    background-color: white;
    color: colors.$primary-color;

    &Cross {
      color: colors.$primary-color;
    }
  }
}